<div class="emoji-picker-container">
	<button mat-icon-button color="primary" class="emoji-picker-icon" aria-label="Emoji" (click)="tooglePicker($event)">
		<mat-icon class="">insert_emoticon</mat-icon>
	</button>

	<div *ngIf="showPicker" class="emoji-picker" [ngClass]="position" [@fadeInOut]>
		<emoji-mart
			emoji="point_up"
			[i18n]="textEmojiMart"
			[isNative]="true"
			[showPreview]="false"
			(emojiClick)="emojiClick($event)"
			(emojiSelect)="emojiSelect($event)"
		></emoji-mart>
	</div>
</div>
