import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { fuseAnimations } from "@fuse/animations";
import { Subject } from "rxjs";

@Component({
	selector: "fb-emoji-picker",
	templateUrl: "./emoji-picker.component.html",
	styleUrls: ["./emoji-picker.component.scss"],
	animations: fuseAnimations,
})
export class EmojiPickerComponent implements OnInit, OnDestroy {
	private _unsubscribeAll: Subject<any>;

	@Input() position: "top-left" | "top-right" | "bottom-left" | "bottom-right" | "fixed";
	@Output() emojiSel = new EventEmitter<any>();

	showPicker: boolean;
	textEmojiMart: any = {
		search: "Buscar",
		emojilist: "Lista de emoji",
		notfound: "Emoji no encontrado",
		clear: "Limpiar",
		categories: {
			search: "",
			recent: "",
			people: "",
			nature: "",
			foods: "",
			activity: "",
			places: "",
			objects: "",
			symbols: "",
			flags: "",
			custom: "",
		},
		skintones: {
			1: "Tono de piel por defecto",
			2: "Tono de piel claro",
			3: "Tono de piel medio-claro",
			4: "Tono de piel medio",
			5: "Tono de piel medio-oscuro",
			6: "Tono de piel oscuro",
		},
	};

	constructor() {
		this._unsubscribeAll = new Subject();

		this.showPicker = false;
	}

	ngOnInit(): void {
		if (!this.position) {
			this.position = "bottom-right";
		}
	}

	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	tooglePicker(event?: Event) {
		if (event) {
			event.stopPropagation();
			event.preventDefault();
		}

		this.showPicker = !this.showPicker;
	}

	emojiClick(event: Event) {
		// console.log("emojiClick", event);
	}

	emojiSelect(event) {
		// console.log("emojiSelect", event);
		this.tooglePicker();
		this.emojiSel.emit(event);
	}
}
