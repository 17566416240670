<div class="title">
	<mat-icon *ngIf="data.showSuccess" class="success">check_circle</mat-icon>
	<mat-icon *ngIf="data.showError" class="error">error</mat-icon>
	<h1 matDialogTitle>{{ data.confirmTitle }}</h1>
</div>

<div mat-dialog-content>{{ data.confirmMessage }}</div>

<div mat-dialog-actions class="pt-24 action-buttons">
	<button *ngIf="data.showCancelButton" mat-button (click)="dialogRef.close(false)">Cancelar</button>
	<button mat-raised-button (click)="dialogRef.close(true)">OK</button>
</div>
