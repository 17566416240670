import { Component, Inject } from "@angular/core";
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
	selector: "fuse-confirm-dialog",
	templateUrl: "./confirm-dialog.component.html",
	styleUrls: ["./confirm-dialog.component.scss"],
})
export class FuseConfirmDialogComponent {
	public confirmMessage: string;

	/**
	 * Constructor
	 *
	 * @param {MatDialogRef<FuseConfirmDialogComponent>} dialogRef
	 */
	constructor(
		@Inject(MAT_DIALOG_DATA) public data,
		public dialogRef: MatDialogRef<FuseConfirmDialogComponent>,
	) {
		// console.log(data);
	}
}
